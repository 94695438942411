<template>
  <div>
    <v-container >
      <DialogConfirmation
        tag="delete"
        title="Excluir Disponibilidade"
        :show="dialog"
        @close="dialog = false"
        @confirm="deleteDisponibilidade()"
      >
        <template v-slot:body>
          <span>TEM CERTEZA QUE DSEJA EXCLUIR ESSA DISPONIBILIDADE?</span>
        </template>
      </DialogConfirmation>

      <v-row>
        <v-col cols="12" lg="5">
        <TitleH2 title="Filial"/>
        <AutocompletePaginadoMain
          tituloItem="nome"
          descricaoItem="cnpj"
          mascaraDescricao="##.###.###/####-##"
          firstIcon="card-account-details"
          placeholder="Filial"
          mensagemItemNaoEncontrado="Nenhuma filial encontrada"
          :key="keyParceiro"
          :filled="true"
          :rounded="true"
          :dense="true"
          :items="parceiros"
          @update:value="onChangeParceiro($event)"
          @scrollDown="atualizarListaParceiros"
          @pesquisa="buscarParceiroPorNome($event)"
          @onBlur="$event && $event.clear && receberParceiros()"
          @onClear="$event && $event.clear && receberParceiros()"
        />
      </v-col>

      <v-col sm="12" md="5">
        <TitleH2 title="Colaborador" />
        <AutoCompleteMain
          firstIcon="account"
          placeholder="Colaborador"
          :key="keyColaborador"
          :data="colaboradores"
          :itemValue="'id'"
          :exibirDetalhes="false"
          :notFound="mensagemSelect || 'Primeiro selecione uma filial'"
          @eventSelected="onChangeColaborador($event)"
        />
      </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <TitleH2 title="Serviço" />
          <ChipGroup
            :key="keyChipsServicos"
            :isMultiple="true"
            :hasMultipleValues="true"
            :tags="servicos"
            @changeTag="selecionarServicos($event)"
          ></ChipGroup>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="12" md="6">
          <TitleH2  title="Informe Disponibilidade" />

          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="dia in diasSemana" :key="dia.id">
                  <td class="text-left">{{ dia.descricao }}</td>
                  <td class="text-left">
                    <ChipGroup
                      :key="keyTabelaDisponibilidade"
                      :isMultiple="true"
                      :hasMultipleValues="true"
                      :tags="turnos"
                      @changeTag="diaTurno({ turnos: $event, dia })"
                    ></ChipGroup>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>

        <v-col sm="12" md="6">
          <TitleH2 title="Disponibilidade Informada" />
          <ChipGroup
            v-if="servicosColaborador.length"
            :tags="servicosColaborador"
            :key="keyChipsServicosColaborador"
            :disabled="true"
          ></ChipGroup>
          <AlertMain type="warning"
            v-if="
              colaborador &&
              disponibilidadeColaborador.length === 0 &&
              disponibilidadeColaboradorVespertino.length === 0
            "
            message="Colaborador não tem disponibilidade cadastrada."
          />

          <v-row
            v-if="
              disponibilidadeColaborador.length > 0 ||
              disponibilidadeColaboradorVespertino.length > 0
            "
          >
            <v-col cols="6">
              <v-list-item
                v-for="dia in disponibilidadeColaborador"
                :key="dia.id"
                two-line
              >
                <v-list-item-content>
                  <v-list-item-title
                    >{{ dia.nomeDia }} -
                    {{ dia.descricaoTurno }}</v-list-item-title
                  >
                  <v-list-item-subtitle>
                    <strong
                      >{{ dia.horaInicio }} - {{ dia.horafim }}</strong
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action style="cursor: pointer">
                  <v-icon @click="openDeleteModal(dia.id)" color="red">mdi-delete</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-col>

            <v-col cols="6">
              <v-list-item
                v-for="dia in disponibilidadeColaboradorVespertino"
                :key="dia.id"
                two-line
              >
                <v-list-item-content>
                  <v-list-item-title
                    >{{ dia.nomeDia }} -
                    {{ dia.descricaoTurno }}</v-list-item-title
                  >
                  <v-list-item-subtitle>
                    <strong
                      >{{ dia.horaInicio }} - {{ dia.horafim }}</strong
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-list-item-action style="cursor: pointer">
                  <v-icon @click="openDeleteModal(dia.id)" color="red">mdi-delete</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-col>
          </v-row>

          <AlertMain v-else type="info"
            message="Selecione uma filial e um colaborador para visualizar sua disponibilidade."
          />
        </v-col>
      </v-row>

      <v-card-actions>
        <ButtonMain
          :primary="true"
          text="Cadastrar"
          @click.native="cadastrar()"
        />
      </v-card-actions>
    </v-container>
  </div>
</template>

<script>
import AutoCompleteMain from '../../components/atoms/fields/AutocompleteMain'
import AutocompletePaginadoMain from '../../components/atoms/fields/AutocompletePaginadoMain.vue'
import ButtonMain from '../../components/atoms/buttons/ButtonMain.vue'
import TitleH2 from '../../components/atoms/title/TitleH2'
import ChipGroup from '@/components/molecules/lists/ChipGroupMain'
import CalendarMain from '../../components/atoms/calendars/CalendarMain'
import AlertMain from '@/components/atoms/AlertMain'
import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'
import api from '@/services/api'
import institucionalService from '@/services/InstitucionalService'
import colaboradorService from '@/services/ColaboradorService'

export default {
  components: {
    AutoCompleteMain,
    AutocompletePaginadoMain,
    TitleH2,
    ChipGroup,
    ButtonMain,
    CalendarMain,
    AlertMain,
    DialogConfirmation,
  },

  data() {
    return {
      keyParceiro: 0,
      keyColaborador: 0,
      keyChipsServicos: 0,
      keyTabelaDisponibilidade: 0,
      keyChipsServicosColaborador: 0,
      paginaAtualSelectParceiro: 0,
      quantidadeManual: false,
      dialog: false,
      loadingButton: false,
      disponibilidadeId: null,
      colaborador: null,
      mensagemSelect: "",
      mensagemErroPadrao: "Falha na conexão. Por favor, tente mais tarde.",
      parceiros: [],
      servicosSelecionados: null,
      colaboradores: [],
      servicosColaborador: [],
      disponibilidadeColaborador: [],
      disponibilidadeColaboradorVespertino: [],
      servicos: [],
      turnos: [],
      relacaoDiaTurnos: [],
      diasSemana: [
        { id: 0, descricao: 'SEGUNDA' },
        { id: 1, descricao: 'TERÇA' },
        { id: 2, descricao: 'QUARTA' },
        { id: 3, descricao: 'QUINTA' },
        { id: 4, descricao: 'SEXTA' },
        { id: 5, descricao: 'SÁBADO' },
        { id: 6, descricao: 'DOMINGO' },
      ],
    }
  },

  created() {
    this.receberParceiros();
    this.allServicos();
    this.allTurnos();
  },

  methods: {
    receberParceiros() {
      const paginacao = { page: 0, size: 10 };
      institucionalService
        .filiaisAtivas(paginacao)
        .then(({ data }) => {
          this.parceiros = data.content;
        });
    },

    atualizarListaParceiros() {
      const paginacao = { page: ++this.paginaAtualSelectParceiro, size: 10 };
      institucionalService
        .filiaisAtivas(paginacao)
        .then(({ data }) => {
          if(data.numberOfElements <= 0) {
            this.paginaAtualSelectParceiro = -1;
            return;
          }

          this.parceiros = [...this.parceiros, ...data.content];
        });
    },

    onSelectChange() {
      this.turnos = [];
      this.servicos = [];
      this.servicosSelecionados = [];
      this.relacaoDiaTurnos = [];
      this.allTurnos();
      this.allServicos();
    },

    onChangeParceiro(parceiroId) {
      this.$root.vtoast.close();
      const resetComponentes = { parceiro: false, colaborador: true, servico: true, tabelaDisponibilidade: true };
      this.resetarComponentes(resetComponentes);
      this.onSelectChange();
      this.colaborador = null;
      this.disponibilidadeColaborador = [];
      this.disponibilidadeColaboradorVespertino = [];
      this.colaboradores = [];
      this.servicosColaborador = [];
      this.mensagemSelect = "";

      if(!parceiroId) return;
      this.buscarColaboradoresPorParceiro(parceiroId);
    },

    onChangeColaborador(colaborador) {
      this.$root.vtoast.close();
      this.resetarComponentes({ parceiro: false, colaborador: false, servico: true, tabelaDisponibilidade: true });
      this.onSelectChange();
      this.colaborador = colaborador;
      this.servicosColaborador = [];
      this.mensagemSelect = "";

      if(!colaborador) {
        this.disponibilidadeColaborador = [];
        this.disponibilidadeColaboradorVespertino = [];
        return;
      }
    },

    buscarParceiroPorNome(termo){
      const tamanhoTermo = termo.length;
      if(tamanhoTermo === 0) {
        this.paginaAtualSelectParceiro = -1;
        this.receberParceiros();
        return;
      }

      institucionalService.filialPorNome(termo)
        .then(({ data }) => {
          this.parceiros = data;
        });
    },

    selecionarServicos(servicos) {
      servicos.length > 0
        ? this.servicosSelecionados = this.eliminarItensRepetidos(servicos, 'id')
        : this.servicosSelecionados = [];
    },

    resetarComponentes({ parceiro, colaborador, servico, tabelaDisponibilidade, servicosColaborador }) {
      parceiro ? this.keyParceiro++ : null;
      colaborador ? this.keyColaborador++ : null;
      servico ? this.keyChipsServicos++ : null;
      tabelaDisponibilidade ? this.keyTabelaDisponibilidade++ : null;
      servicosColaborador ? this.keyChipsServicosColaborador++ : null;
    },

    buscarColaboradoresPorParceiro(parceiroId) {
      colaboradorService.colaboradorPorFilial(parceiroId)
        .then(({ data }) => {
          if(data.length <= 0) {
            this.mensagemSelect = "Nenhum colaborador associado a esta filial";
            return;
          }

          this.colaboradores = data.map(colaborador => {
            return {
              ...colaborador,
              avatar: `${process.env.VUE_APP_API_URL_DICEBEAR}?seed=${colaborador.nome}`
            }
          });
        })
        .catch((erro) => {
          const mensagemErro = erro.response.data;
          this.$root.vtoast.show({
            status: 'error',
            title: mensagemErro.titulo || this.mensagemErroPadrao,
            text: mensagemErro.mensagemUsuario || '',
          });
        });
    },

    allTurnos() {
      api.getTurnos().then(({ data }) => {
        this.turnos = data
      })
    },

    allServicos() {
      api.getServicos().then(({ data }) => {
        this.servicos = data
      })
    },

    diaTurno({ turnos, dia }) {
      if(turnos.length <= 0) {
        const itemDuplicado = this.relacaoDiaTurnos.find(relacao => relacao.dia === dia.id);
        const indiceItemDuplicado = this.relacaoDiaTurnos.indexOf(itemDuplicado);
        this.relacaoDiaTurnos.splice(indiceItemDuplicado, 1);
        return;
      }

      if (this.relacaoDiaTurnos.some(relacao => relacao.dia === dia.id)) {
        this.relacaoDiaTurnos = this.relacaoDiaTurnos.map(relacao => {
          return {
            ...relacao,
            turnos: [...new Set(turnos.map(turno => turno.id))]
          }
        });
        return;
      }

      const ultimosTurnosSelecionados = turnos.slice(-2);
      const turnosEDiaSelecionados = {
        turnos: ultimosTurnosSelecionados.map(turno => turno.id),
        dia: dia.id,
      }
      this.relacaoDiaTurnos.push(turnosEDiaSelecionados);
    },

    convertListToObjectDay(dias) {
      const items = [];
      for (const dia of dias) {
        dia.turnos = [...new Set(dia.turnos)],
        dia.turnos.forEach(turno => items.push({ dia: dia.dia, turno }));
      }

      return items;
    },

    cadastrar() {
      if (!this.colaborador) {
        this.$root.vtoast.show({
          status: 'warning',
          text: 'Selecione um colaborador.',
          title: 'Atenção!',
        });
        return;
      }

      if (!this.servicosSelecionados.length) {
        this.$root.vtoast.show({
          status: 'warning',
          text: 'Selecione um serviço.',
          title: 'Atenção!',
        });
        return;
      }

      if (!this.relacaoDiaTurnos.length) {
        this.$root.vtoast.show({
          status: 'warning',
          text: 'Selecione pelo menos um turno.',
          title: 'Atenção!',
        });
        return;
      }

      this.loadingButton = true
      this.$store.dispatch('loading', true)

      const lista = this.convertListToObjectDay(this.relacaoDiaTurnos)
      const agenda = {
        colaborador: this.colaborador.id,
        //especializacao: this.colaborador.idEspecializacao,
        relacaoDiaTurnos: lista,
        servico: this.servicosSelecionados.map(servico => servico.id),
      };

      api
        .postDisponibilidadeColaborador(agenda)
        .then(_ => {
          this.disponibilidadeColaborador = [];
          this.disponibilidadeColaboradorVespertino = [];
          this.onSelectChange();
          const resetComponentes = { tabelaDisponibilidade: true, servicosColaborador: true};
          this.resetarComponentes(resetComponentes);

          this.$root.vtoast.show({
            status: 'success',
            text: 'Disponibildade cadastrada.',
            title: 'SUCESSO!',
            time: 10000,
          })
          this.loadingButton = false

          api
            .getDisponibilidadeColaboradorAndServico(this.colaborador.id)
            .then(({ data }) => {
              this.servicosColaborador = data.servicos;
              this.disponibilidadeColaboradorVespertino = data.disponibilidades
                .filter(disp => disp.turno === 2)
                .sort((a, b) => a.dia > b.dia);

              this.disponibilidadeColaborador = data.disponibilidades
                .filter(disp => disp.turno === 1)
                .sort((a, b) => a.dia > b.dia);
            })
            .catch(err => {
              this.disponibilidadeColaborador = []
              this.$root.vtoast.show({
                status: 'error',
                text: err.response?.data.mensagemUsuario,
                title: err.response?.data.titulo,
              })
            })
          this.$store.dispatch('loading', false)
        })
        .catch(err => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo,
          })
          this.loadingButton = false
          this.$store.dispatch('loading', false)
        });
    },

    openDeleteModal(disponibilidadeId) {
      this.disponibilidadeId = disponibilidadeId
      this.dialog = true
    },

    deleteDisponibilidade() {
      api
        .deleteDisponibilidade(this.disponibilidadeId)
        .then(_ => {
          api
            .getDisponibilidadeColaboradorAndServico(this.colaborador.id)
            .then(({ data }) => {
              this.servicosColaborador = data.servicos;
              this.disponibilidadeColaboradorVespertino = data.disponibilidades
                .filter(disp => disp.turno === 2)
                .sort((a, b) => a.dia > b.dia);

              this.disponibilidadeColaborador = data.disponibilidades
                .filter(disp => disp.turno === 1)
                .sort((a, b) => a.dia > b.dia);
            })
            .catch(() => {
              this.onSelectChange();
              this.servicosColaborador = [];
              this.disponibilidadeColaborador = [];
              this.disponibilidadeColaboradorVespertino = [];
            });
        })
        .catch(err => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo,
          });
        })

      this.dialog = false
    },

    eliminarItensRepetidos(itens, chave) {
    return itens.filter((item, index, self) =>
        index === self.findIndex(el => el[chave] === item[chave])
    );
  }
  },

  watch: {
    colaborador(colaboradorAtual, anterior) {
      if (colaboradorAtual) {
        api
          .getDisponibilidadeColaboradorAndServico(colaboradorAtual.id)
          .then(({ data }) => {
            this.servicosColaborador = data.servicos
            this.disponibilidadeColaboradorVespertino = data.disponibilidades
              .filter(disp => disp.turno === 2)
              .sort((a, b) => a.dia > b.dia);

            this.disponibilidadeColaborador = data.disponibilidades
              .filter(disp => disp.turno === 1)
              .sort((a, b) => a.dia > b.dia);
          })
          .catch(erro => {
            this.disponibilidadeColaborador = []
            this.disponibilidadeColaboradorVespertino = []

            if(erro.response.status && erro.response.status !== 404) {
              this.$root.vtoast.show({
                status: 'error',
                text: erro.response?.data.mensagemUsuario,
                title: erro.response?.data.titulo +  ' ' + erro.response.status,
              });
            }

          })
      }
    },
  },
}
</script>

<style scoped>
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
</style>
